.home-container {
  .img_qr {
    width: 50%;
    @media screen and (max-width: 768px) {
      width: 80%;
    }
  }
  .text-info-content {
    // margin-left: 140px;

    @media screen and (max-width: 768px) {
      margin-left: 0;
    }
  }
  .text-title {
    @media screen and (max-width: 768px) {
      font-size: 20px;
    }
  }
}
